// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 999999;
}

ion-spinner {
  width: 80px;
  height: 80px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvYWRpbmcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxZQUFBO0VBQ0EsbUJBQUE7RUFDQSx1QkFBQTtFQUNBLGFBQUE7RUFDQSxTQUFBO0VBQ0EsVUFBQTtFQUNBLGtCQUFBO0VBQ0Esa0JBQUE7RUFDQSxXQUFBO0VBQ0EsZUFBQTtBQUNKOztBQUNBO0VBQ0ksV0FBQTtFQUNBLFlBQUE7QUFFSiIsImZpbGUiOiJsb2FkaW5nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiZGl2e1xuICAgIGhlaWdodDogMTAwJTtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgbWFyZ2luOiAwO1xuICAgIHBhZGRpbmc6IDA7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICB3aWR0aDogMTAwJTtcbiAgICB6LWluZGV4OiA5OTk5OTk7XG59XG5pb24tc3Bpbm5lciB7XG4gICAgd2lkdGg6IDgwcHg7XG4gICAgaGVpZ2h0OiA4MHB4O1xuICB9Il19 */`, "",{"version":3,"sources":["webpack://./src/app/components/loading/loading.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;AACJ;;AACA;EACI,WAAA;EACA,YAAA;AAEJ;AACA,wxBAAwxB","sourcesContent":["div{\n    height: 100%;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n    margin: 0;\n    padding: 0;\n    position: absolute;\n    text-align: center;\n    width: 100%;\n    z-index: 999999;\n}\nion-spinner {\n    width: 80px;\n    height: 80px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
