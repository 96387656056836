// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `canvas-draw {
    height: 100%;
    width: 100%;
    display: block;
  
    #top-toolbar {
      position: absolute;
      top: 0;
    }
  
    #bottom-toolbar {
      position: absolute;
      bottom: 0;
    }
  }
  canvas{
    background-color: #e3e3e3;
  }
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhbnZhcy1kcmF3LmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7SUFDSSxZQUFZO0lBQ1osV0FBVztJQUNYLGNBQWM7O0lBRWQ7TUFDRSxrQkFBa0I7TUFDbEIsTUFBTTtJQUNSOztJQUVBO01BQ0Usa0JBQWtCO01BQ2xCLFNBQVM7SUFDWDtFQUNGO0VBQ0E7SUFDRSx5QkFBeUI7RUFDM0IiLCJmaWxlIjoiY2FudmFzLWRyYXcuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbImNhbnZhcy1kcmF3IHtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgZGlzcGxheTogYmxvY2s7XG4gIFxuICAgICN0b3AtdG9vbGJhciB7XG4gICAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgICB0b3A6IDA7XG4gICAgfVxuICBcbiAgICAjYm90dG9tLXRvb2xiYXIge1xuICAgICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgICAgYm90dG9tOiAwO1xuICAgIH1cbiAgfVxuICBjYW52YXN7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogI2UzZTNlMztcbiAgfSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/canvas-draw/canvas-draw.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,cAAc;;IAEd;MACE,kBAAkB;MAClB,MAAM;IACR;;IAEA;MACE,kBAAkB;MAClB,SAAS;IACX;EACF;EACA;IACE,yBAAyB;EAC3B;AACF,wwBAAwwB","sourcesContent":["canvas-draw {\n    height: 100%;\n    width: 100%;\n    display: block;\n  \n    #top-toolbar {\n      position: absolute;\n      top: 0;\n    }\n  \n    #bottom-toolbar {\n      position: absolute;\n      bottom: 0;\n    }\n  }\n  canvas{\n    background-color: #e3e3e3;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
